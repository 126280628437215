.home-container10 {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  overflow-x: hidden;
  flex-direction: column;
}
.home-hero {
  width: 100%;
  height: 1000px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  background-color: #5228F5;
}
.home-header1 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-mobile-navigation {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 150;
  position: fixed;
  transform: translateX(100%);
  transition: transform 0.3s;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.home-logo1 {
  width: 64px;
}
.home-links1 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  flex-direction: column;
}
.home-close-mobile-menu {
  top: var(--dl-space-space-oneandhalfunits);
  right: var(--dl-space-space-oneandhalfunits);
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-icon10 {
  width: 40px;
  height: 40px;
  display: flex;
}
.home-desktop-navigation {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-oneandhalfunits);
  align-items: center;
  flex-direction: column;
}
.home-centered {
  width: 100%;
  display: flex;
  max-width: 1200px;
  box-sizing: initial;
  align-items: center;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  justify-content: space-between;
  background-color: #ffffff;
}
.home-left1 {
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: 38px;
  flex-direction: row;
  padding-bottom: 38px;
}
.home-logo2 {
  width: 105px;
  margin-left: var(--dl-space-space-halfunit);
}
.home-links2 {
  display: flex;
  align-items: center;
  margin-left: 33px;
  flex-direction: row;
}
.home-navlink {
  text-decoration: none;
}
.home-link1 {
  text-decoration: none;
  color: #000;
}
.home-link2 {
  text-decoration: none;
  color: #000;
}
.home-right1 {
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-get-started1 {
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 56px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  background-color: #000000;
}
.home-get-started20 {
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-threeunits);
  border-radius: 10px;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  background-color: #000000;
}
.home-get-started1:hover {
  background-color: #5228F5;
}
.home-text104 {
  color: rgb(255, 255, 255);
}
.home-burger-menu {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-mobile-menu-button {
  width: 40px;
  height: 40px;
  display: none;
}
.home-container12 {
  display: contents;
}
.home-content1 {
  display: flex;
  margin-top: 140px;
  align-items: center;
  flex-direction: column;
}
.home-text105 {
  color: rgb(255, 255, 255);
  font-size: 72px;
  font-style: normal;
  text-align: center;
  font-weight: 700;
  line-height: 80px;
}
.home-text106 {
  font-size: 52px;
  line-height: 60px;
}
.home-text108 {
  color: rgb(238, 233, 254);
  font-size: 20px;
  max-width: 600px;
  margin-top: 16px;
  text-align: center;
  line-height: 30px;
}
.home-get-started2 {
  color: white;
  cursor: pointer;
  display: flex;
  margin-top: 40px;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 56px;
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  background-color: #000000;
}
.home-get-started2:hover {
  color: black;
  background-color: #ffffff;
}
.home-text109 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.home-dashboard-preview {
  top: 650px;
  display: flex;
  position: absolute;
  align-items: center;
  border-color: rgba(255, 255, 255, 0.2);  /* 50% transparent white border */
  border-width: 30px;
  border-radius: 100px;
  flex-direction: column;
  background-color: rgba(99, 61, 246, 0.1);  /* 50% transparent purple background */
}
.home-outline {
  display: flex;
  align-items: center;
  border-color: #7350f7;
  border-width: 20px;
  border-radius: 70px;
  flex-direction: column;
  background-color: #7350f7;
}
.home-image1 {
  width: 900px;
  z-index: 10;
  user-select: none;
  border-radius: 70px;
}
.home-features {
  width: 100%;
  display: flex;
  z-index: 1;
  align-items: center;
  padding-top: 400px;
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  background-color: EFF0F2;
}
.home-title {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-text110 {
  font-size: 56px;
  font-style: normal;
  text-align: center;
  font-weight: 700;
  line-height: 72px;
}
.home-text111 {
  font-size: 20px;
  margin-top: 16px;
  text-align: center;
  line-height: 30px;
}
.home-text113 {
  font-size: 20px;
  font-weight: 700;
}
.home-cards1 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  margin-top: 80px;
  align-items: center;
  flex-direction: column;
}
.home-container13 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.home-icon12 {
  width: 40px;
  height: 40px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text114 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text115 {
  line-height: 24px;
}
.home-publish {
  height: initial;
}
.home-icon13 {
  width: 40px;
  height: 40px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text116 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text117 {
  line-height: 24px;
}
.home-container14 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.home-icon14 {
  width: 40px;
  height: 40px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text118 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: 16px;
}
.home-text119 {
  line-height: 24px;
}
.home-icon15 {
  width: 40px;
  height: 40px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text120 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text121 {
  line-height: 24px;
}
.home-container15 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-sixunits);
  flex-direction: row;
}
.home-icon16 {
  width: 40px;
  height: 40px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text122 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: 16px;
}
.home-text123 {
  font-size: 27px;
}
.home-text125 {
  line-height: 24px;
}
.home-icon17 {
  width: 40px;
  height: 40px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text126 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text127 {
  line-height: 24px;
}
.home-quote-container {
  width: 100%;
  display: none;
  align-items: center;
  padding-top: 160px;
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  padding-bottom: 160px;
  justify-content: center;
  background-color: #000000;
}
.home-quote1 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  flex-direction: column;
  justify-content: center;
}
.home-message {
  color: rgb(255, 255, 255);
  font-size: 40px;
  max-width: 800px;
  line-height: 52px;
}
.home-author {
  width: 800px;
  display: flex;
  margin-top: var(--dl-space-space-oneandhalfunits);
  align-items: center;
  flex-direction: row;
}
.home-avatar {
  width: 64px;
  height: 64px;
  object-fit: cover;
  border-radius: 50%;
}
.home-quote2 {
  color: rgb(151, 126, 249);
  width: 100%;
  max-width: 800px;
  margin-left: var(--dl-space-space-unit);
}
.home-text128 {
  font-style: normal;
  font-weight: 600;
}
.home-statistics {
  width: 100%;
  height: 264px;
  display: none;
  align-items: center;
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  justify-content: center;
  background-color: #5228F5;
}
.home-container16 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-banners {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-banner-manage {
  width: 100%;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
}

.home-container17 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-left2 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.home-text130 {
  text-align: left;
}
.home-text131 {
  color: rgb(0, 0, 0);
  line-height: 24px;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-get-started3 {
  transition: 0.3s;
  margin-bottom: 0;
}
.home-get-started3:hover {
  color: var(--dl-color-gray-white);
  background-color: var(--dl-color-template-blue-bg);
}
.home-text132 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.home-image-container1 {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-cards-image1 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  user-select: none;
}
.home-banner-advanced-analytics {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
  background-color: #EFF0F2;
}
.home-centered-container1 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  justify-content: center;
}
.home-image-container2 {
  flex: 1;
  display: flex;
}
.home-cards-image2 {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.home-right2 {
  flex: 1;
  display: flex;
  margin-left: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.home-text133 {
  text-align: left;
  line-height: 68px;
}
.home-category1 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
.home-text134 {
  color: rgb(0, 0, 0);
  font-size: 28px;
  line-height: 35px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-integrations {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-sevenunits);
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #EFF0F2;
}
.home-centered-container2 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-heading1 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-text136 {
  color: rgb(0, 0, 0);
  text-align: center;
  margin-bottom: var(--dl-space-space-fiveunits);
}
.home-pills-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-pills {
  width: 100%;
  display: flex;
  grid-gap: var(--dl-space-space-twounits);
  flex-wrap: wrap;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: row;
  justify-content: center;
}
.home-container18 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  position: relative;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}
.home-how-it-works {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  background-color: #211062;
}
.home-text137 {
  color: var(--dl-color-gray-white);
}
.home-container19 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.home-heading2 {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
  justify-content: center;
}
.home-centered-container3 {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 1200px;
  align-items: flex-end;
  flex-direction: column;
  justify-content: space-between;
}
.home-category2 {
  color: white;
  width: 100%;
  display: flex;
  overflow: hidden;
  padding-left: var(--dl-space-space-fourunits);
  border-radius: 50px;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: row;
  background-color: #5228F5;
}
.home-headng1 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  margin-right: var(--dl-space-space-fourunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
}
.home-text138 {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 16px;
}
.home-text139 {
  color: rgb(238, 233, 254);
  line-height: 24px;
  margin-bottom: 40px;
}
.home-get-started4 {
  margin-bottom: 0px;
}
.home-get-started4:hover {
  color: black;
  background-color: #ffffff;
}
.home-text142 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.home-container20 {
  flex: 1;
  height: 100%;
  display: flex;
  align-self: flex-end;
  flex-direction: row;
}
.home-pasted-image1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.home-row {
  display: flex;
  grid-gap: var(--dl-space-space-threeunits);
  align-items: center;
  flex-direction: row;
}
.home-category3 {
  flex: 1;
  color: white;
  height: 555px;
  display: flex;
  overflow: hidden;
  align-items: flex-end;
  border-radius: 50px;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
}
.home-headng2 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fourunits);
  flex-direction: column;
  padding-bottom: 0px;
}
.home-text143 {
  color: rgb(0, 0, 0);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 16px;
}
.home-text144 {
  color: rgb(30, 30, 30);
}
.home-pasted-image2 {
  width: 100%;
}
.home-pasted-image10 {
  width: 100%;
  margin-top: 150px;
}
.home-category4 {
  flex: 1;
  color: white;
  height: 555px;
  display: flex;
  overflow: hidden;
  align-items: flex-end;
  border-radius: 50px;
  flex-direction: column;
  justify-content: space-between;
  background-color: #000000;
}
.home-headng3 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fourunits);
  flex-direction: column;
  padding-bottom: 0px;
}
.home-text145 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 16px;
}
.home-text146 {
  color: rgb(238, 233, 254);
}
.home-pasted-image3 {
  width: 100%;
  object-fit: contain;
}
.home-pricing {
  width: 100%;
  display: none;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
  background-color: #5228F5;
}
.home-centered-container4 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-heading3 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-text147 {
  color: var(--dl-color-gray-white);
  text-align: center;
}
.home-text148 {
  color: #EEE9FE;
  width: 600px;
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-selection {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-text149 {
  color: #EEE9FE;
  width: 128px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: rgba(255, 255, 255, 0.3);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 2px;
}
.home-text150 {
  color: #ffffff;
  width: 128px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #ffffff;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 2px;
}
.home-cards2 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  margin-top: var(--dl-space-space-fiveunits);
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  grid-template-columns: 1fr 1fr 1fr;
}
.home-card1 {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-threeunits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: flex-start;
  background-color: #ffffff;
}
.home-text151 {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text152 {
  color: rgb(0, 0, 0);
  line-height: 24px;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-get-started5 {
  transition: 0.3s;
}
.home-get-started5:hover {
  color: var(--dl-color-gray-white);
  background-color: var(--dl-color-template-blue-bg);
}
.home-text153 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.home-text154 {
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-bullet-points1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-point10 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.home-icon18 {
  fill: #5228F5;
  width: 8px;
  height: 8px;
}
.home-text155 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-left: var(--dl-space-space-halfunit);
}
.home-point11 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.home-icon20 {
  fill: #5228F5;
  width: 8px;
  height: 8px;
}
.home-text156 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-left: var(--dl-space-space-halfunit);
}
.home-card2 {
  flex: 1;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: 40px;
  border-radius: 50px;
  padding-right: 40px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: flex-start;
  background-color: #ffffff;
}
.home-text157 {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text158 {
  color: rgb(0, 0, 0);
  line-height: 24px;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-get-started6 {
  transition: 0.3s;
}
.home-get-started6:hover {
  color: var(--dl-color-gray-white);
  background-color: var(--dl-color-template-blue-bg);
}
.home-text162 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.home-text165 {
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-bullet-points2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-point12 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.home-icon22 {
  fill: #5228F5;
  width: 8px;
  height: 8px;
}
.home-text166 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-left: var(--dl-space-space-halfunit);
}
.home-point13 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.home-icon24 {
  fill: #5228F5;
  width: 8px;
  height: 8px;
}
.home-text167 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-left: var(--dl-space-space-halfunit);
}
.home-point14 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.home-icon26 {
  fill: #5228F5;
  width: 8px;
  height: 8px;
}
.home-text168 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-left: var(--dl-space-space-halfunit);
}
.home-point15 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.home-icon28 {
  fill: #5228F5;
  width: 8px;
  height: 8px;
}
.home-text169 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-left: var(--dl-space-space-halfunit);
}
.home-card3 {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: 40px;
  border-radius: 50px;
  padding-right: 40px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: flex-start;
  background-color: #000000;
}
.home-text170 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text171 {
  color: rgb(255, 255, 255);
  line-height: 24px;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-get-started7 {
  color: var(--dl-color-gray-black);
  transition: 0.3s;
  background-color: #ffffff;
}
.home-get-started7:hover {
  color: var(--dl-color-gray-white);
  background-color: var(--dl-color-template-blue-bg);
}
.home-text174 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.home-text177 {
  color: #ffffff;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-bullet-points3 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-point16 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.home-icon30 {
  fill: #5228F5;
  width: 8px;
  height: 8px;
}
.home-text178 {
  color: #ffffff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-left: var(--dl-space-space-halfunit);
}
.home-point17 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.home-icon32 {
  fill: #5228F5;
  width: 8px;
  height: 8px;
}
.home-text179 {
  color: #ffffff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-left: var(--dl-space-space-halfunit);
}
.home-point18 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.home-icon34 {
  fill: #5228F5;
  width: 8px;
  height: 8px;
}
.home-text180 {
  color: #ffffff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-left: var(--dl-space-space-halfunit);
}
.home-point19 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.home-icon36 {
  fill: #5228F5;
  width: 8px;
  height: 8px;
}
.home-text181 {
  color: #ffffff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-left: var(--dl-space-space-halfunit);
}
.home-testimonals {
  width: 100%;
  display: flex;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
}
.home-left3 {
  width: 375px;
  display: flex;
  margin-top: 375px;
  align-items: flex-start;
  margin-right: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.home-text182 {
  text-align: left;
}
.home-right3 {
  gap: 40px;
  display: flex;
  flex-direction: row;
}
.home-column1 {
  gap: 40px;
  display: flex;
  flex-direction: column;
}
.home-name1 {
  color: rgb(0, 0, 0);
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.home-name2 {
  color: rgb(0, 0, 0);
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.home-name3 {
  color: rgb(0, 0, 0);
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.home-name4 {
  color: rgb(0, 0, 0);
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.home-column2 {
  gap: 40px;
  display: flex;
  flex-direction: column;
}
.home-name5 {
  color: rgb(0, 0, 0);
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.home-name6 {
  color: rgb(0, 0, 0);
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.home-name7 {
  color: rgb(0, 0, 0);
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.home-name8 {
  color: rgb(0, 0, 0);
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.home-text185 {
  cursor: pointer;
  display: none;
}
.home-action-bar {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-sixunits);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-action {
  width: 100%;
  display: flex;
  max-width: 1300px;
  align-items: center;
  border-radius: 30px;
  flex-direction: column;
  padding: var(--dl-space-space-fourunits);
  background-color: #5228F5;
  position: relative;
}

.home-heading4 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: column;
}

.home-text188 {
  color: white;
  font-size: 3.5rem;
  text-align: center;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-twounits);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.home-text189 {
  color: var(--dl-color-gray-white);
  margin-bottom: 30px;
}

.home-images {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--dl-space-space-threeunits);
  padding: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-fourunits);
}

.home-image2 {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 16px;
  transition: transform 0.3s ease;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
}

.home-image2:hover {
  transform: translateY(-8px);
  box-shadow: 0 12px 28px rgba(0, 0, 0, 0.25);
}


/* .template-button {
  color: var(--dl-color-gray-white);
  padding: 15px 30px;
  border-radius: 25px;
  background-color: rgba(255, 255, 255, 0.2);
  cursor: pointer;
  transition: background-color 0.3s;
}

.template-button:hover {
  background-color: rgba(255, 255, 255, 0.3);
} */
.home-text189 {
  color: rgb(238, 233, 254);
  line-height: 24px;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-get-started8 {
  position: absolute;
  bottom: var(--dl-space-space-threeunits);
  right: var(--dl-space-space-threeunits);
  padding: var(--dl-space-space-unit) var(--dl-space-space-twounits);
  background: black;
  color: white;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.home-get-started8:hover {
  transform: translateY(-2px);
  color: black;
  background-color: #ffffff;
  
}
.home-text198 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
/* .home-images {
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: space-between;
}
.home-image2 {
  width: 100%;
  object-fit: cover;
  user-select: none;
} */
.home-faq {
  width: 100%;
  display: flex;
  padding: 120px;
  align-items: center;
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: center;
  background-color: #EFF0F2;
}
.home-heading5 {
  width: 100%;
  display: flex;
  align-items: center;
  user-select: none;
  flex-direction: column;
}
.home-text201 {
  text-align: center;
}
.home-accordion {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-header2 {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.home-text202 {
  width: 100%;
  font-size: 20px;
  max-width: 760px;
  font-style: normal;
  font-weight: 500;
}
.home-text203 {
  width: 100%;
  max-width: 760px;
  line-height: 24px;
}
.home-header3 {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.home-text220 {
  width: 100%;
  font-size: 20px;
  max-width: 760px;
  font-style: normal;
  font-weight: 500;
}
.home-text221 {
  width: 100%;
  max-width: 760px;
  line-height: 24px;
}
.home-header4 {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.home-text222 {
  width: 100%;
  font-size: 20px;
  max-width: 760px;
  font-style: normal;
  font-weight: 500;
}
.home-text223 {
  width: 100%;
  max-width: 760px;
  line-height: 24px;
}
.home-header5 {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.home-text238 {
  width: 100%;
  font-size: 20px;
  max-width: 760px;
  font-style: normal;
  font-weight: 500;
}
.home-text239 {
  width: 100%;
  max-width: 760px;
  line-height: 24px;
}
.home-header6 {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.home-text254 {
  width: 100%;
  font-size: 20px;
  max-width: 760px;
  font-style: normal;
  font-weight: 500;
}
.home-text255 {
  width: 100%;
  max-width: 760px;
  line-height: 24px;
}
.home-container22 {
  display: contents;
}
.home-footer {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sevenunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sevenunits);
  background-color: #000000;
}
.home-top {
  width: 100%;
  display: flex;
  max-width: 1200px;
  border-color: #3B3B3B;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
  border-bottom-width: 1px;
}
.home-left4 {
  width: 33%;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-twounits);
  flex-direction: column;
}
.home-text268 {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-subscription {
  width: 100%;
  height: 66px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 50px;
  margin-bottom: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: space-between;
  background-color: #ffffff;
}
.home-textinput {
  width: 60%;
  outline: none;
  font-size: 14px;
  border-width: 0px;
}
.home-subscribe {
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: 16px;
  padding-left: 32px;
  border-radius: 56px;
  padding-right: 32px;
  flex-direction: row;
  padding-bottom: 16px;
  justify-content: flex-start;
  background-color: #5228F5;
}
.home-subscribe:hover {
  background-color: #000;
}
.home-text269 {
  color: #ffffff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}
.home-contact {
  display: flex;
  margin-top: 52px;
  align-items: flex-start;
  flex-direction: column;
}
.home-text270 {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
}
.home-link3 {
  color: rgb(196, 196, 196);
}
.home-right4 {
  gap: var(--dl-space-space-fiveunits);
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--dl-space-space-threeunits);
  flex-direction: row;
  justify-content: flex-start;
}
.home-category5 {
  width: 240px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text271 {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
}
.home-links3 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text272 {
  color: rgb(196, 196, 196);
}
.home-text273 {
  color: rgb(196, 196, 196);
}
.home-text274 {
  color: rgb(196, 196, 196);
}
.home-text275 {
  color: rgb(196, 196, 196);
}
.home-text276 {
  color: rgb(196, 196, 196);
}
.home-category6 {
  width: 240px;
  display: flex;
  align-items: stretch;
  border-color: #000000;
  border-style: dashed;
  border-width: 2px;
  flex-direction: column;
}
.home-category7 {
  width: 240px;
  display: flex;
  flex-direction: column;
}
.home-text277 {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
}
.home-links4 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text278 {
  color: rgb(196, 196, 196);
}
.home-text279 {
  color: rgb(196, 196, 196);
}
.home-bottom {
  width: 100%;
  display: flex;
  max-width: 1200px;
  margin-top: var(--dl-space-space-threeunits);
  align-items: flex-start;
  user-select: none;
  flex-direction: column;
}
.home-branding {
  width: var(--dl-size-size-large);
  /* filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);; */
  margin-bottom: var(--dl-space-space-unit);
  margin-left: -3px;
}
.home-text280 {
  color: rgb(104, 104, 104);
}

@media(max-width: 991px) {
  .home-hero {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-close-mobile-menu {
    align-items: center;
    justify-content: center;
  }
  .home-desktop-navigation {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-centered {
    height: 60px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .home-left1 {
    margin-left: var(--dl-space-space-halfunit);
  }
  .home-logo2 {
    width: 64px;
  }
  .home-links2 {
    display: none;
  }
  .home-get-started1 {
    display: none;
  }
  .home-mobile-menu-button {
    display: flex;
  }
  .home-content1 {
    width: 100%;
    max-width: 1200px;
  }
  .home-image1 {
    width: 100%;
    border-radius: 25px;
  }
  .home-features {
    padding-top: 260px;
  }
  .home-title {
    width: 100%;
    max-width: 1200px;
  }
  .home-text139 {
    color: rgb(238, 233, 254);
    line-height: 20px;
    margin-bottom: 40px;
    width: '100%';
  }
  .home-link1 {
    text-decoration: none;
    color: #000;
    font-size: 24px;
    margin-left: 10px;
    font-weight: bold;
  }
  .home-link2 {
    text-decoration: none;
    color: #000;
    font-size: 24px;
    margin-left: 10px;
    font-weight: bold;
  }
  .home-text110 {
    font-size: 36px;
    line-height: 40px;
  }
  .home-text111 {
    font-size: 20px;
    margin-top: var(--dl-space-space-unit);
    line-height: 30px;
  }
  .home-text106 {
    font-size: 32px;
    line-height: 40px;
  }
  .home-cards1 {
    margin-top: var(--dl-space-space-fiveunits);
  }
  .home-text115 {
    line-height: 21px;
  }
  .home-text117 {
    line-height: 21px;
  }
  .home-text119 {
    line-height: 21px;
  }
  .home-text120 {
    font-size: 24px;
  }
  .home-text121 {
    line-height: 21px;
  }
  .home-text125 {
    line-height: 21px;
  }
  .home-text126 {
    font-size: 24px;
  }
  .home-text127 {
    line-height: 21px;
  }
  .home-quote-container {
    padding-top: var(--dl-space-space-sevenunits);
    padding-bottom: var(--dl-space-space-sevenunits);
  }
  .home-message {
    width: 100%;
    max-width: 800px;
  }
  .home-author {
    width: 100%;
    max-width: 800px;
  }
  .home-statistics {
    height: 800px;
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-container16 {
    height: 100%;
    flex-direction: column;
  }
  .home-banner-manage {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-left2 {
    margin-right: var(--dl-space-space-threeunits);
  }
  .home-cards-image1 {
    width: 400px;
    height: 400px;
  }
  .home-banner-advanced-analytics {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-category2 {
    padding-left: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .home-headng1 {
    margin-left: var(--dl-space-space-fourunits);
  }
  .home-container20 {
    width: 100%;
  }
  .home-pasted-image1 {
    height: auto;
  }
  .home-headng2 {
    /* padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits); */
  }
  .home-headng3 {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
  }
  .home-text148 {
    width: 100%;
    max-width: 600px;
  }
  .home-cards2 {
    display: flex;
    flex-direction: column;
  }
  .home-card1 {
    width: 100%;
    align-items: flex-start;
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-text152 {
    font-size: 16px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-text154 {
    font-size: 16px;
  }
  .home-card2 {
    width: 100%;
    align-items: flex-start;
  }
  .home-card3 {
    width: 100%;
    align-items: flex-start;
  }
  .home-testimonals {
    align-items: center;
    flex-direction: column;
  }
  .home-left3 {
    display: 'flex';
    flex-direction: column;
    margin-top: 0px;
    align-items: center;
    margin-bottom: ThreeUnits;
    margin-right: var(--dl-space-space-zerounits);
  }
  .home-text182 {
    font-size: 28px;
    text-align: center;
    line-height: 35px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-right3 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: row;
  }
  .home-column1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-column2 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-action {
    flex-direction: column;
  }
  .home-heading4 {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-text188 {
    font-size: 36px;
    line-height: 36px;
  }
  .home-container17 {
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    max-width: 90%;
  }
  .home-accordion {
    width: 100%;
    max-width: 1200px;
  }
  .home-footer {
    padding: var(--dl-space-space-sixunits);
  }
  .home-top {
    flex-direction: column;
  }
  .home-left4 {
    width: 100%;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-text270 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-link3 {
    text-decoration: underline none;
  }
  .home-right4 {
    gap: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .home-links3 {
    gap: var(--dl-space-space-unit);
  }
  .home-links4 {
    gap: var(--dl-space-space-unit);
  }
  .home-branding {
    width: var(--dl-size-size-medium);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .home-text280 {
    font-size: 12px;
    line-height: 25px;
    margin-bottom: 0px;
  }
  .home-logo2 {
    margin: 0px;
    width: 250px;
    height: 35px;
    padding: 0px;
  }
}
@media(max-width: 767px) {
  .home-hero {
    height: 620px;
    position: relative;
    padding-bottom: 180px;
    background-color: var(--dl-color-template-blue-bg);
  }
  .home-desktop-navigation {
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-centered {
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-left1 {
    margin-left: var(--dl-space-space-unit);
  }
  .home-right1 {
    margin-right: var(--dl-space-space-unit);
  }
  .home-get-started1 {
    display: none;
  }
  .home-content1 {
    height: 100%;
    margin-top: var(--dl-space-space-fourunits);
    justify-content: center;
  }
  .home-text105 {
    color: rgb(255, 255, 255);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
  }
  .home-text108 {
    color: rgb(238, 233, 254);
    font-size: 16px;
    margin-top: var(--dl-space-space-unit);
    line-height: 24px;
  }
  .home-get-started2 {
    margin-top: var(--dl-space-space-twounits);
  }
  .home-dashboard-preview {
    top: 520px;
    width: 90%;
    border-width: 10px;
    border-radius: 45px;
  }
  .home-outline {
    border-width: 10px;
    border-radius: 35px;
  }
  .home-image1 {
    width: 100%;
  }
  .home-features {
    padding-top: 350px;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-text110 {
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
  }
  .home-container13 {
    gap: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .home-text114 {
    font-size: 24px;
  }
  .home-text115 {
    font-size: 14px;
  }
  .home-publish {
    margin-left: 0px;
  }
  .home-text116 {
    font-size: 24px;
  }
  .home-text117 {
    font-size: 14px;
  }
  .home-container14 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .home-analyze1 {
    margin-right: 0px;
  }
  .home-text118 {
    font-size: 24px;
  }
  .home-text119 {
    font-size: 14px;
  }
  .home-text121 {
    font-size: 14px;
  }
  .home-container15 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .home-analyze2 {
    margin-right: 0px;
  }
  .home-text122 {
    font-size: 24px;
  }
  .home-text123 {
    font-size: 24px;
  }
  .home-text125 {
    font-size: 14px;
  }
  .home-text127 {
    font-size: 14px;
  }
  .home-quote-container {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-message {
    font-size: 24px;
    line-height: 31px;
  }
  .home-author {
    width: 100%;
  }
  .home-avatar {
    width: 40px;
    height: 40px;
  }
  .home-quote2 {
    font-size: 14px;
    max-width: 800px;
  }
  .home-statistics {
    height: 100%;
  }
  .home-container16 {
    gap: var(--dl-space-space-fourunits);
    justify-content: center;
  }
  .home-banner-manage {
    padding-top: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-container17 {
    flex-direction: column;
  }
  .home-left2 {
    margin-right: 0px;
  }
  .home-text130 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text131 {
    line-height: 24px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-text132 {
    font-size: 16px;
  }
  .home-image-container1 {
    justify-content: center;
  }
  .home-cards-image1 {
    margin-top: var(--dl-space-space-threeunits);
  }
  .home-banner-advanced-analytics {
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-centered-container1 {
    flex-direction: column-reverse;
  }
  .home-right2 {
    margin-left: 0px;
  }
  .home-text133 {
    font-size: 28px;
    text-align: left;
    line-height: 26px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-text134 {
    font-size: 18px;
    line-height: 20px;
    margin-bottom: var(--dl-space-space-unit);

  }
  .home-integrations {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-text136 {
    line-height: 24px;
  }
  .home-pills {
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-how-it-works {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-text137 {
    margin-bottom: 0px;
  }
  .home-heading2 {
    margin-bottom: var(--dl-space-space-threeunits);
    /* padding-right: var(--dl-space-space-zerounits); */
  }
  .home-headng1 {
    padding-bottom: 0px;
  }
  .home-get-started4 {
    display: none;
  }
  .home-container20 {
    width: 100%;
  }
  .home-row {
    height: auto;
    flex-direction: column;
  }
  .home-pricing {
    width: 100%;
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
  }
  .home-card1 {
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-text151 {
    font-size: 28px;
  }
  .home-card2 {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-text157 {
    font-size: 28px;
  }
  .home-card3 {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-text170 {
    font-size: 28px;
  }
  .home-testimonals {
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
    padding-bottom: 0px;
  }
  .home-right3 {
    width: 100%;
    flex-direction: column;
  }
  .home-name1 {
    font-size: 14px;
  }
  .home-text184 {
    font-size: 14px;
  }
  .home-name2 {
    font-size: 14px;
  }
  .home-name3 {
    font-size: 14px;
  }
  .home-name4 {
    font-size: 14px;
  }
  .home-column2 {
    display: none;
  }
  .home-name5 {
    font-size: 14px;
  }
  .home-name6 {
    font-size: 14px;
  }
  .home-name7 {
    font-size: 14px;
  }
  .home-name8 {
    font-size: 14px;
  }
  .home-text185 {
    display: flex;
    font-style: normal;
    margin-top: var(--dl-space-space-oneandhalfunits);
    font-weight: 600;
  }
  .home-action-bar {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-action {
    align-items: center;
  }
  .home-heading4 {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-text188 {
    font-size: 28px;
    text-align: left;
  }
  .home-text198 {
    color: var(--dl-color-gray-white);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
  }
  .home-faq {
    padding: var(--dl-space-space-fourunits);
  }
  .home-heading5 {
    width: 100%;
  }
  .home-text201 {
    font-size: 26px;
    line-height: 36px;
  }
  .home-footer {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  /* Portfolio section */
  .home-action-bar {
    padding: var(--dl-space-space-twounits);
  }

  .home-action {
    padding: var(--dl-space-space-twounits);
  }

  .home-text188 {
    font-size: 2.5rem;
  }

  .home-images {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    grid-template-columns: none;
    gap: var(--dl-space-space-unit);
    padding: var(--dl-space-space-unit);
    scroll-snap-type: x mandatory;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
  }

  .home-images::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }

  .home-image2 {
    min-width: 280px;
    height: 250px;
    scroll-snap-align: center;
    margin-right: var(--dl-space-space-unit);
  }

  .home-image2:last-child {
    margin-right: 0;
  }

  /* Add scroll indicator dots */
  .scroll-dots {
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-top: var(--dl-space-space-unit);
  }

  .dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.3);
    transition: background-color 0.3s ease;
  }

  .dot.active {
    background-color: white;
  }

  .home-get-started8 {
    position: relative;
    bottom: auto;
    right: auto;
    margin-top: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .home-hero {
    padding-bottom: 160px;
  }
  .home-logo1 {
    margin-left: 0px;
  }
  .home-icon10 {
    align-items: center;
    justify-content: center;
  }
  .home-text108 {
    color: #EEE9FE;
  }
  .home-get-started2 {
    width: 100%;
  }
  .home-dashboard-preview {
    top: 500px;
  }
  .home-features {
    padding-top: 200px;
  }
  .home-cards1 {
    margin-top: var(--dl-space-space-threeunits);
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-container13 {
    gap: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-container14 {
    gap: var(--dl-space-space-oneandhalfunits);
    margin-bottom: 0px;
  }
  .home-analyze1 {
    margin-bottom: 0px;
  }
  .home-container15 {
    gap: var(--dl-space-space-oneandhalfunits);
    margin-bottom: 0px;
  }
  .home-analyze2 {
    margin-bottom: 0px;
  }
  .home-statistics {
    height: 500px;
  }
  .home-text131 {
    line-height: 24px;
  }
  .home-cards-image1 {
    width: 100%;
    margin-top: var(--dl-space-space-twounits);
  }
  .home-category2 {
    padding-left: var(--dl-space-space-zerounits);
    padding-right: var(--dl-space-space-zerounits);
    flex-direction: column;
  }
  .home-pricing {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-get-started7 {
    background-color: var(--dl-color-gray-white);
  }
  .home-testimonals {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-action-bar {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-footer {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-subscription {
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
}
