.contactContainer {
  /* min-height: 100vh; */
  padding: 2rem;
  background-color: #EFF0F2;
  color: #5228F5;
}

.contactHeader {
  text-align: center;
  padding: 3rem 0;
  max-width: 800px;
  margin: 0 auto;
}

.contactHeader h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #000;
}

.contactHeader p {
  font-size: 1.3rem;
  color: #666;
}

.contactContent {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  padding-bottom: 4rem;
}

.contactInfo {
  background-color: #5228F5;
  border-radius: 1rem;
  padding: 2rem;
  color: white;
}

.contactForm {
  background-color: white;
  border-radius: 1rem;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.contactForm h2 {
  color: #000;
}

.infoList {
  margin: 2rem 0;
}

.infoItem {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1.5rem;
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 1rem;
  margin-top: 0.25rem;
  color: white;
}

.infoItem h3 {
  margin: 0 0 0.5rem 0;
  font-size: 1.1rem;
}

.infoItem p {
  margin: 0;
  opacity: 0.9;
}

.mapContainer {
  width: 100%;
  height: 350px;
  border-radius: 0.75rem;
  overflow: hidden;
  margin-top: 2rem;
}

.mapContainer iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.formGroup {
  margin-bottom: 1.5rem;
}

.formGroup label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #333;
}

.formGroup input,
.formGroup textarea {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  background-color: white;
  color: #333;
  font-size: 1rem;
  transition: all 0.2s ease;
}

.formGroup input:focus,
.formGroup textarea:focus {
  outline: none;
  border-color: #5228F5;
  box-shadow: 0 0 0 2px rgba(82, 40, 245, 0.1);
}

.submitButton {
  width: 100%;
  padding: 1rem;
  background-color: #5228F5;
  color: white;
  border: none;
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transition: background-color 0.2s;
}

.submitButton:hover {
  background-color: #4020DD;
}

.sendIcon {
  width: 18px;
  height: 18px;
}

/* Mobile Responsiveness */
@media (max-width: 991px) {
  .contactContent {
    grid-template-columns: 1fr;
    margin: 0;
  }

  .contactHeader {
    padding: 2rem 0;
  }

  .contactHeader h1 {
    font-size: 2rem;
  }
}

/* Input placeholder styles */
.formGroup input::placeholder,
.formGroup textarea::placeholder {
  color: #999;
}