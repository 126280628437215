.you-tube-you-tube {
  width: 240px;
  height: 120px;
  display: flex;
  align-items: center;
  border-radius: 200px;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.you-tube-pasted-image {
  width: 120px;
}
.you-tuberoot-class-name {
  flex: 1;
  height: auto;
  align-self: stretch;
}
@media(max-width: 479px) {
  .you-tube-you-tube {
    width: 140px;
    height: 90px;
    align-items: center;
  }
  .you-tube-pasted-image {
    width: 85px;
    border-radius:200px; ;
  }
}
